import { Links, Meta, MetaFunction, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import Header from "~/components/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/lib/rdt/radixProvider";

import '../styles/app.css';
// import { appsignal } from "./lib/appsignal";

export function links() {
	return [
		// { rel: 'stylesheet', href: styles },
		{ rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://hug.meme/hug.png' },
		{ rel: 'mask-icon', sizes: 'any', href: 'https://hug.meme/hug.png', color: '#5D0FC0' },
	];
}

export const meta: MetaFunction = () => {
	return [{ title: 'HUG Meme' }];
};

export default function AppLayout() {
	return (
		<html lang="en" className="bg-fixed bg-top bg-cover bg-no-repeat bg-hug">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#f97316" media="(prefers-color-scheme: light)" />
				<meta name="theme-color" content="#c2410c" media="(prefers-color-scheme: dark)" />
				<Meta />
				<Links />
			</head>
			<body>
				<RadixProvider>
					<Header />
					<main className="mt-5 md:mt-12">
						<Outlet />
						<TransactionNotification />
						<ScrollRestoration />
					</main>
				</RadixProvider>
				<Scripts />
			</body>
		</html>
	);
}
